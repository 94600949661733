<template lang="pug">
sidebar-accordion(:title="$t('customClass')")
  om-custom-class(v-if="showCustomClass" property="customClasses" :showAccordion="false")
</template>
<script>
  import customClass from '@/editor/components/sidebar/inputs/OmElementCustomClass.vue';

  export default {
    components: { 'om-custom-class': customClass },
    props: {
      showCustomClass: { type: Boolean, default: true },
    },
  };
</script>
